const slick_setup = {
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 5,
  prevArrow: '<div class="dp-arrow prev"><i class="fas fa-chevron-left"></i></div>',
  nextArrow: '<div class="dp-arrow next"><i class="fas fa-chevron-right"></i></div>',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 300,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

export default slick_setup