import PageController from '../../PageController'

class Controller extends PageController {
  constructor() {
    super();
  };
}

const RadioMain = (function() {
  return {
    init: () => {
      let controller = new Controller();
    }
  }
})()

export default RadioMain;