import { parseQuery } from '../../helpers/helpers';

const RadioOpenSearch = (() => {
  let state = {
    docs: []
  }

  let request = null;
  let timeout = null;

  const query = input => {
    if (!input.length || input.length < 2) {
      state.fetching = false;
      render();
      return;
    }
    console.log('q: ' + input)
    if (request) request.abort()
    state.fetching = true
    request = $.ajax({
      url: SITE_URL + 'radio/search',
      data: {
        query: input.trim()
      },
      success: response => {
        response = JSON.parse(response)
        state.docs = response
        state.fetching = false
        render()
      }
    })
  }

  const setup = () => {
    $('[name="search"]').focus();
    $('#search_form').on('submit', e => {
      e.preventDefault()
      let val = $('[name="search"]').val()
      state.fetching = true
      render()
      if (timeout) clearTimeout(timeout)
      query(val)
    })

    $('[name="search"]').on("input", e => {
      let val = $(e.target).val()
      if (val.length) {
        window.history.pushState({ urlPath: SITE_URL + 'radio/search?q=' + (val) }, "", SITE_URL + 'radio/search?q=' + (val));
      } else {
        window.history.pushState({ urlPath: SITE_URL + 'radion/search'}, "", SITE_URL + 'radio/search');
      }
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => {
        if (val.length >= 3) {
          state.fetching = true
          render()
          query(val)
        } 
      }, 500)
    })

    $('#results').click(e => {
      let item = $(e.target).closest('.dp-item')
      let id = $(item).attr('data-id')
      if (id) {
        window.location.href = SITE_URL + 'radio?id=' + id
      }
    })
  }

  const render = () => {
    if (state.fetching) {
      $('#results').html(`
        <p class="text-white text-center" style="font-size:50px">
        <i class="fas fa-spinner fa-spin"></i>
        </p>
      `)
      return;
    }
    let results = state.docs.map(item => {
      return `
      <div 
        class="dp-item radio-item col-sm-4 col-xs-6 col-md-3 mb-1" 
        ${item.isSeries ? 
          `data-series="${encodeURIComponent(item.seriesId)}"` :
          `data-id="${item.url}"`
        }
        >
        <div class="dp-item-inner">
          <div class="show-bg" style="background-image: url(${SITE_URL + 'images/m3/' + item.id})"></div>
          <div class="show-gradient"></div>
          <div class="show-play">
          </div>
          <div class="show-title">
            ${item['mainCreator'] ? `
            <h6 class="subtitle">${item['mainCreator']}</h6>
            ` : ""}
            <h5>${item['title']}</h5>
            ${!item.isSeries && item['subtitle'] ? `
            <p class="subtitle">${item['subtitle']}</p>
            ` : ""}
            ${item['duration'] ? `
            <p class="subtitle text-white muted">${item['duration']}</p>
            ` : ""}
          </div>
          
        </div>
      </div>
        `;
    })
    if (results.length) {
      $('#results').html(results.join(''));
    } else {
      $('#results').html('<p class="text-white text-center">Nincsenek találatok...</p>');
    }
  }

  return {
    init: () => {
      setup()
      let get = parseQuery(window.location.href);
      if (get.q && get.q.length > 2) query(get.q) 
    }
  }
})()

export default RadioOpenSearch;