import 'slick-carousel/slick/slick.min.js'
import slick_setup from './slick_setup'
require('slick-carousel/slick/slick.css')

const RadioOpen = (() => {
  let state = {
    collections: [],
    creators: [],
    fetching: []
  }

  const setup = () => {
    $(window).on('scroll', () => {
      renderCollections()
    })

    $('.dp-carousel').click(e => {
      let item = $(e.target).closest('.dp-item')
      let seriesId = $(item).attr('data-series')
      let id = $(item).attr('data-id')
      if (seriesId) {
        window.location.href = SITE_URL + 'radio?series=' + seriesId
      } else if (id) {
        window.location.href = SITE_URL + 'radio?id=' + id
      }
    })

    $('[name="search"]').click(() => {
      window.location.href = SITE_URL + 'radio/search'
    })
  }



  const renderRow = (collection) => {
    if (collection === 'most_viewed' && !state.collections['most_viewed'].length) {
      $('#most_viewed').hide();
      return;
    }
    let row = state.collections[collection].map(item => {
      return `
      <div 
        class="dp-item radio-item" 
        ${item.isSeries && collection !== "most_viewed" ? 
          `data-series="${encodeURIComponent(item.seriesId)}"` :
          `data-id="${item.url}"`
        }
        >
        <div class="dp-item-inner ${ item.rank ? 'ranked' : ''}">
          <div class="show-bg" style="background-image: url(${SITE_URL + 'images/m3/' + item.id})"></div>
          <div class="show-gradient"></div>
          ${ item.rank ? `
          <div class="show-rank">
            #${item.rank}
          </div>
          ` : ''}
          <div class="show-title">
            ${item['mainCreator'] ? `
            <h6 class="subtitle">${item['mainCreator']}</h6>
            ` : ""}
            <h5>${item['title'].trim()}</h5>
            ${!item.isSeries && item['subtitle'] ? `
            <h6 class="subtitle">${item['subtitle']}</h6>
            ` : ""}
            ${!item.isSeries && item['duration'] ? `
            <h6 class="subtitle text-white muted">${item['duration']}</h6>
            ` : ""}
            ${item.isSeries && !item.rank ? `
            <h6 class="subtitle">${item['episodes']} rész</h6>
            ` : ""}
            ${item.isSeries && item.rank ? `
            <h6 class="subtitle">${item['subtitle']}</h6>
            ` : ""}
          </div>
        </div>
      </div>
        `;
    }).join('')
    let creators_sorted = (state.creators[collection] || []).sort((a,b) => {
      var nameA = a.programmeProperties_creatorTitle_str[0].toUpperCase();
      var nameB = b.programmeProperties_creatorTitle_str[0].toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    })
    let creators = creators_sorted.map(creator => {
      return `<a class="btn btn-xs btn-primary btn-dark" href="${SITE_URL + 'radio?creator=' + creator.id}">${creator.programmeProperties_creatorTitle_str[0]}</a>`;
    }).join(' ')
    
    $(`[data-collection="${collection}"]`).html(row)
    $(`[data-creators="${collection}"]`).html(creators)
    setTimeout(() => {
      $(`[data-collection="${collection}"]`).slick(slick_setup);
      $(`[data-collection="${collection}"]`).removeClass('loading')
    }, 100)
  }

  const getCollections = () => {
    let collections = Array.from($('[data-collection]')).map(item => $(item).attr('data-collection'))
    state.collections = collections.reduce((result, collection) => {
      result[collection] = []
      return result
    }, {})
    console.log(state.collections)
  }

  const renderCollections = (init = false) => {
    Object.keys(state.collections).forEach((collection,index) => {
      let inView = $(`[data-collection="${collection}"]`).isInViewport()
      if (init) {
        if (index < 4) {
          fetchCollection(collection, () => {
            renderRow(collection)
          })
        }
      } else {
        if (inView && !state.collections[collection].length && !state.fetching.includes(collection)) {
          fetchCollection(collection, () => {
            renderRow(collection)
          })
        }
      }
    })
  }

  const fetchCollection = (collection, callback) => {
    console.log('fetching ', collection)
    if (state.fetching.includes(collection)) return;
    state.fetching.push(collection)
    $.ajax({
      method: 'GET',
      url: SITE_URL + 'radio/get-open',
      data: { collection },
      success: response => {
        response = JSON.parse(response)
        state.fetching = state.fetching.filter(item => item !== collection)
        state.collections[collection] = response.docs
        state.creators[collection] = response.creators
        console.log(response)
        callback()
      }
    })
  }

  return {
    init: () => {
      setup()
      getCollections()
      renderCollections(true)
    }
  }
})()

export default RadioOpen;