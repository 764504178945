const RadioOpenSeries = (() => {
  return {
    init: () => {
       
      $('[data-id]').click(function() {
        let id = $(this).attr('data-id')
        window.location.href = SITE_URL + 'radio?id=' + id
        
      })
    }
  }
})()

export default RadioOpenSeries;