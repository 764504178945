import App from '../app';
import RadioMain from './radioMain/RadioMain';
import RadioOpen from './radioOpen/RadioOpen';
import RadioOpenItem from './radioOpen/RadioOpenItem';
import RadioOpenSeries from './radioOpen/RadioOpenSeries';
import RadioOpenSearch from './radioOpen/RadioOpenSearch';
import RadioOpenCollection from './radioOpen/RadioOpenCollection';
import RadioOpenCreator from './radioOpen/RadioOpenCreator';

const Modules = {
    RadioMain,
    RadioOpen,
    RadioOpenItem,
    RadioOpenSeries,
    RadioOpenSearch,
    RadioOpenCollection,
    RadioOpenCreator,
}

App.init(Modules);