const RadioOpenCreator = (() => {

  const shuffleHighlights = () => {
    let highlights = Array.from($('.collection-highlight-item'))
    highlights.forEach(hl => $(hl).hide());
    let index = 0;
    let timeout = null;
    const shuffle = () => {
      highlights.forEach(hl => $(hl).fadeOut());
      highlights.forEach(hl => $(hl).removeClass('active'));
      $(highlights[index]).fadeIn();
      $(highlights[index]).addClass('active');
      timeout = setTimeout(() => {
        if (index + 1 < highlights.length) {
          index += 1;
        } else {
          index = 0
        }
        shuffle()
      }, 5000)
    }
    shuffle()
  }

  return {
    init: () => {
      $('[data-id],[data-series]').click(function() {
        let seriesId = $(this).attr('data-series')
        let id = $(this).attr('data-id')
        if (seriesId) {
          window.location.href = SITE_URL + 'radio?series=' + seriesId
        } else if (id) {
          window.location.href = SITE_URL + 'radio?id=' + id
        }
      })

      shuffleHighlights()
    }
  }
})()

export default RadioOpenCreator;