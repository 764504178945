import '@nava/keyosplayer/keyos-player'

require('@nava/keyosplayer/styles/keyos-default.css')
require('@nava/keyosplayer/styles/default-style.css')
require('../../../css/keyos-custom.css')

const RadioOpenItem = (() => {
    const setupPlayer = () => {
        let id = $('#player').attr('data-id');
        console.log(id)
        getStreamData(id, stream => {
            let setup = createKeyOSSetup(ITEM, stream)
            keyosPlayer.init('player', setup)
            let player = keyosPlayer.getPlayer('player')
            player.on('ready', () => {
                $('.m3-open-player-loader').hide();
            })
            player.on('error', () => {
                $('.m3-open-player-loader').hide();
            })
            player.on('play', () => {
                $('.radio-player-container').addClass("playing")
            })
            player.on('pause', () => {
                $('.radio-player-container').removeClass("playing")
            })
            /*
            player.on('idle', () => {
                $('.radio-player-container').removeClass("playing")
            })
            */
            player.on('ended', () => {
                $('.radio-player-container').removeClass("playing")
            })

            $('.radio-player-container').on('click', e => {
                if (!$(e.target).parents('.open-radio-player').length) {
                    if (player.paused()) {
                        player.play()
                    } else {
                        player.pause()
                    }
                }
            })
        })
    }

    const getStreamData = (id, callback) => {
        $.ajax({
            url: 'api/m3/v3/stream',
            data: {
                target: id,
                platform: 'web',
                type: 'radio',
            },
            method: 'GET',
            success: response => {
                response = JSON.parse(response)
                console.log(response)
                callback(response)
            }
        })
    }

    const createKeyOSSetup = (item, source) => {
        let sourceMpegDash = source.mpeg_dash;
        let sourceHls = source.hls;

        if (item && item.hasSubtitle && !item.isLivePlaying) {
            let subtitles = [{
                type: 'srt',
                path: '/subtitle/' + item.id + '.srt',
                language: 'hungarian',
                label: 'Magyar',
                default: false,
            }]

            sourceMpegDash['subtitles'] = subtitles;
            sourceHls['subtitles'] = subtitles;
        }

        let sources;
        if (!source.mpeg_dash.drm && source.hls.drm) {
            sources = [sourceHls, sourceMpegDash]
        } else {
            sources = [sourceMpegDash, sourceHls]
        }

        let setup = keyosSetupDefaults();
        if (item && item.id) {
            setup['image'] = '/images/m3/' + item.id + '.jpg';
        }
        setup['playlist'] = {
            'sources': sources
        };
        setup['drm'] = {
            'playready': {
                laUrl: `${source.proxy_url}?drm-type=playready&type=${source.type}`
            },
            'widevine': {
                laUrl: `${source.proxy_url}?drm-type=widevine&type=${source.type}`
            },
            'fairplay': {
                certificateUri: sourceHls.certificateUri,
                laUrl: `${source.proxy_url}?drm-type=fairplay&type=${source.type}`
            }
        }
        return setup;
    }

    const keyosSetupDefaults = () => {
        return {
            'height': '30px',
            'autoplay': true,
            'extModules': `/public/build/modules/${KEYOSVERSION}/`,
            'mute': false,
        }
    }

    return {
        init: () => {
            setupPlayer()
            $('[data-id],[data-series]').on('click', function() {
                let seriesId = $(this).attr('data-series')
                let id = $(this).attr('data-id')
                if (seriesId) {
                    window.location.href = SITE_URL + 'radio?series=' + seriesId
                } else if (id) {
                    window.location.href = SITE_URL + 'radio?id=' + id
                }
            })

            $('#scroll_to_ep_list').on('click', function(event) {
                var target = $($('#full_ep_list'));

                if (target.length) {
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top - 20
                    }, 500);
                }
            });

            $('#short_description').on('click', e => {
                $('#short_description').hide();
                $('#full_description').removeClass('hidden')
            })

            $('#short_contributors').on('click', e => {
                $('#short_contributors').hide();
                $('#full_contributors').removeClass('hidden')
            })

            $('#hide_full_description').on('click', e => {
                $('#short_description').show();
                $('#full_description').addClass('hidden')
            })

            $('#hide_full_contributors').on('click', e => {
                $('#short_contributors').show();
                $('#full_contributors').addClass('hidden')
            })
        }
    }
})()

export default RadioOpenItem;